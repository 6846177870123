<template>
  <div>
    <div class="form-group">
      <label for="startDate">開始日期：</label>
      <input id="startDate" type="date" v-model="startDate">
      <label for="endDate">結束日期：</label>
      <input id="endDate" type="date" v-model="endDate">
      <button class="btn btn-primary" @click="searchData">搜尋</button>
    </div>

    <div class="result">
      <div class="result-item">
        <h2>登錄次數：</h2>
        <p>{{ loginCount }}</p>
      </div>
      <div class="result-item">
        <h2>訂單次數：</h2>
        <p>{{ orderCount }}</p>
      </div>
      <div class="result-item">
        <h2>使用時間次數：</h2>
        <p>{{ useTimeCount }}</p>
      </div>
      <div class="result-item">
        <h2>申請次數：</h2>
        <p>{{ applyCount }}</p>
      </div>
      <div class="result-item">
        <h2>產品次數：</h2>
        <p>{{ productCount }}</p>
      </div>
      <div class="result-item">
        <h2>店鋪次數：</h2>
        <p>{{ storeCount }}</p>
      </div>
    </div>

    <div>
      <line-chart :chartData="chartData"></line-chart>
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import storage from 'libs/storage/localStorage.js';

export default {
  extends: Line,
  data() {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    return {
      startDate: oneMonthAgo.toISOString().slice(0, 10),
      endDate: lastDayOfMonth.toISOString().slice(0, 10),
      loginCount: 0,
      orderCount: 0,
      useTimeCount: 0,
      applyCount: 0,
      productCount: 0,
      storeCount: 0,
      chartData: {},
    };
  },
  methods: {
    async loadData(providerId, startDate, endDate) {
      try {
        const res = await this.$api.collection.providerApi.operationRecord(providerId, startDate, endDate);

        this.loginCount = res.login_count;
        this.orderCount = res.order_count;
        this.useTimeCount = res.use_time_count;
        this.applyCount = res.apply_count;
        this.productCount = res.product_count;
        this.storeCount = res.store_count;

        this.updateChartData(res.use_time_records);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },
    updateChartData(useTimeRecords) {
      const dates = useTimeRecords.map(record => record.date);
      const counts = useTimeRecords.map(record => record.count);

      this.chartData = {
        labels: dates,
        datasets: [
          {
            label: '使用時間（分鐘）',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            data: counts,
          },
        ],
      };

      this.renderChart(this.chartData);
    },
    async searchData() {
      const providerId = storage().get('chooseProviderId');
      await this.loadData(providerId, this.startDate, this.endDate);
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

.result {
  display: flex;
  flex-wrap: wrap;
}

.result-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 10px;
}

.result-item h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.result-item p {
  font-size: 16px;
}
</style>
